import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentInstrumento = () => {
    const data = useStaticQuery(graphql`
    {
      nodePage(id: {eq: "ce646c60-0bfa-5b91-9504-71fe91608c9a"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }}></div>
        </div>
    )
}

export default ComponentInstrumento

