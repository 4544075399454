import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col, Nav, Tab } from "react-bootstrap"

const ComponentPublicaciones = () => {
  const data = useStaticQuery(graphql`
    {
       ponencias: nodePage(
        id: {eq: "f0bdaae0-a654-577e-9461-f97b07b2c0f7"}
      ) {
        title
        body {
          value
        }
        created
      }
      articulos: nodePage(
        id: {eq: "2617d2ba-8f53-5f24-8f29-a1dff01131bc"}
      ) {
        title
        body {
          value
        }
        created
      }
    }
  `)
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={12}>
          <Nav variant="pills" style={{justifyContent: `center`}}>
             <Nav.Item class="card3 wallet">
              <div class="overlay"></div>
              <div class="circle3">
                <Nav.Link eventKey="second" style={{ borderRadius: `55%`, textAlign: `center`, zIndex: '1' }}><img style={{ zIndex: '1', height: "72px", width: "64px" }} src="https://oerunesco.tec.mx/sites/default/files/inline-images/explanation.png" width={`20%`} /></Nav.Link>
              </div>
              <p>Ponencias</p>
            </Nav.Item>
            <Nav.Item class="card3 human-resources">
              <div class="overlay"></div>
              <div class="circle3">
                <Nav.Link eventKey="three" style={{borderRadius: `55%`, textAlign: `center`, zIndex: '1' }}><img style={{ zIndex: '1', height: "72px", width: "64px" }} src="https://oerunesco.tec.mx/sites/default/files/inline-images/magazine%20%281%29_0.png" width={`20%`} /></Nav.Link>
              </div>
              <p>Artículos</p>

            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <br/><br/>
      <Row>
        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey="second">
              {!!(data.ponencias.body)? <div dangerouslySetInnerHTML={{ __html: data.ponencias.body.value }}></div> : null}
            </Tab.Pane>
            <Tab.Pane eventKey="three">
            {!!(data.articulos.body)? <div dangerouslySetInnerHTML={{ __html: data.articulos.body.value }}></div> : null}
           
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default ComponentPublicaciones

