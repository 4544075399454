import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentDiseno = () => {
    const data = useStaticQuery(graphql`
    {
      nodePage(id: {eq: "45b0419a-8870-51cc-93da-492ebb8eab22"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }}></div>
        </div>
    )
}

export default ComponentDiseno

