import * as React from "react"
import {Tabs, Tab } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ComponentInstrumento from "../hooks/instrumento";
import ComponentDiseno from "../hooks/diseno";
import ComponentPublicaciones from "../hooks/publicaciones";

export default function OpenResearchLab () {
  return (
    <Layout pageInfo={{ pageName: "Investigación" }}>
    <SEO title="Investigación" />
    <br />
    <br />
    <br></br>
    <Tabs defaultActiveKey="diseñoMetodo" id="uncontrolled-tab-example" className="mb-4 nav-fill integrantes-tab">
      <Tab eventKey="diseñoMetodo" style={{textAlign: `justify`}} className="pt-3" title="Diseño de Método">
    <ComponentDiseno></ComponentDiseno>
      </Tab>
      <Tab eventKey="instrumento" style={{textAlign: `justify`}} className="pt-3" title="Instrumentos">
      <ComponentInstrumento></ComponentInstrumento>
      </Tab>
      <Tab eventKey="publicaciones" style={{textAlign: `justify`}} className="pt-3" title="Publicaciones">
        <ComponentPublicaciones></ComponentPublicaciones>
      </Tab>
    </Tabs>
  </Layout>
  )
}